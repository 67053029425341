import React from "react"
import Layout from "../components/layout"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import HomeHero from "../components/HomeHero"
import Services from "../components/Services"
import HomeMain from "../components/HomeMain"
import SEO from "../components/seo"
import OrangeCTA from "../components/OrangeCTA"
import { useHomeQuery } from "../hooks/useHomeQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const IndexPage = () => {
  const data = useHomeQuery()
  const short = data.allSanityHome.edges[0].node
  return (
    <Layout>
      <SEO
        title="Magento & Shopify Experts"
      />
      <HomeHero
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
      />
      <Services
        servicesIcon1={short.services1Icon.asset.fluid}
        servicesIcon2={short.services2Icon.asset.fluid}
        servicesIcon3={short.services3Icon.asset.fluid}
        servicesTitle1={short.services1Title}
        servicesTitle2={short.services2Title}
        servicesTitle3={short.services3Title}
        servicesText1={short.services1Text}
        servicesText2={short.services2Text}
        servicesText3={short.services3Text}
        servicesBtn={short.servicesBtn}
      />
      <HomeMain
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainFeatures={short._rawMainFeatures}
        mainBtn={short.mainBtn}
        slug="/shopify-support"
      />
      <OrangeCTA
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
        slug="/website-design"
      />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default IndexPage
import { useStaticQuery, graphql } from "gatsby"
export const useHomeQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityHome {
          edges {
            node {
              pageTitle
              metadescription
              heroTitle
              heroSubtitle
              services1Title
              services1Text
              services2Title
              services2Text
              services3Title
              services3Text
              servicesBtn
              mainTitle
              _rawMainText
              _rawMainFeatures
              mainBtn
              orangeCtaTitle
              _rawOrangeCtaText
              orangeCtaBtn
              heroImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services1Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services2Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              services3Icon {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              orangeCtaOverlayImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}

import React from "react"
import Image from "gatsby-image"
import Button from "../components/Button"

class Services extends React.Component {
  render() {
    const { servicesIcon1, servicesIcon2, servicesIcon3 } = this.props
    const { servicesTitle1, servicesTitle2, servicesTitle3 } = this.props
    const { servicesText1, servicesText2, servicesText3 } = this.props
    const { servicesBtn } = this.props

    return (
      <div className="w-80 relative text-2xl " style={{ marginTop: "-100px" }}>
        <div className="flex flex-col lg:flex-row text-center font-hairline">
          <div
            className="flex-1 p-16 text-white bg-brown lg:mb-80px"
            style={{ padding: "60px" }}
          >
            <div className="flex flex-col items-center">
              <div className="mb-5" style={{ width: "22%" }}>
                <Image fluid={servicesIcon1} />
              </div>
              <div className="mb-5">
                <h3 style={{ fontSize: "26px" }}>{servicesTitle1}</h3>
              </div>
              <div className="mb-5">
                <div className="w-24 border border-2 border-white" />
              </div>
              <div className="mb-10 text-base">
                <p>{servicesText1}</p>
              </div>
              <div className="text-base w-full px-16 flex justify-center">
                <div style={{ maxWidth: "312px", width: "100%" }}>
                  <Button btnText={servicesBtn} bgColor="bg-green" slug="/magento-2-migration" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-1 p-16 text-white bg-orange mt-0 lg:mt-20"
            style={{ padding: "60px" }}
          >
            <div className="flex flex-col items-center">
              <div className="mb-5" style={{ width: "22%" }}>
                <Image fluid={servicesIcon2} />
              </div>
              <div className="mb-5">
                <h3 style={{ fontSize: "26px" }}>{servicesTitle2}</h3>
              </div>
              <div className="mb-5">
                <div className="w-24 border border-2 border-white" />
              </div>
              <div className="mb-10 text-base">
                <p>{servicesText2}</p>
              </div>
              <div className="text-base w-full px-16 flex justify-center">
                <div style={{ maxWidth: "312px", width: "100%" }}>
                  <Button btnText={servicesBtn} bgColor="bg-brown" slug="/magento-support" />
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex-1 p-16 text-white bg-brown lg:mb-80px"
            style={{ padding: "60px" }}
          >
            <div className="flex flex-col items-center">
              <div className="mb-5" style={{ width: "22%" }}>
                <Image fluid={servicesIcon3} />
              </div>
              <div className="mb-5">
                <h3 style={{ fontSize: "26px" }}>{servicesTitle3}</h3>
              </div>
              <div className="mb-5">
                <div className="w-24 border border-2 border-white" />
              </div>
              <div className="mb-10 text-base">
                <p>{servicesText3}</p>
              </div>

              <div className="text-base w-full px-16 flex justify-center">
                <div style={{ maxWidth: "312px", width: "100%" }}>
                  <Button btnText={servicesBtn} bgColor="bg-green" slug="/shopify-support" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Services
